<template>
  <g :fill="color" fill-rule="nonzero">
    <path d="M10 18.273V13.09a.5.5 0 1 0-1 0v5.182a.5.5 0 0 0 1 0z" />
    <path
      d="M6.1 19.636h7.65a.5.5 0 1 0 0-1H6.1a.5.5 0 0 0 0 1zM9.5 12.727c-3.09 0-5.6-2.455-5.6-5.49v-4.99C3.9 1.28 4.697.5 5.675.5h7.65c.978 0 1.775.78 1.775 1.747v4.99c0 3.035-2.51 5.49-5.6 5.49zm0-1c2.543 0 4.6-2.013 4.6-4.49v-4.99a.761.761 0 0 0-.775-.747h-7.65a.761.761 0 0 0-.775.747v4.99c0 2.477 2.057 4.49 4.6 4.49z"
    />
    <path
      d="M4.4 7.41H3.267c-.96 0-1.767-.924-1.767-2.092 0-1.168.808-2.09 1.767-2.09H4.4v-1H3.267C1.723 2.227.5 3.625.5 5.317.5 7.011 1.723 8.41 3.267 8.41H4.4v-1zM14.6 3.227h1.133c.96 0 1.767.923 1.767 2.091 0 1.168-.808 2.091-1.767 2.091H14.6v1h1.133c1.544 0 2.767-1.398 2.767-3.09 0-1.693-1.223-3.092-2.767-3.092H14.6v1z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
